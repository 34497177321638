import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  const [image, setImage] = useState(null);

  // Function to handle image selection
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result); // Display selected image
      };
      reader.readAsDataURL(file);
    }
  };

  // Function to redirect to a given URL
  const handleRedirect = () => {
    window.location.href = "https://my.smartviewmd.com/login";
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="image-container">
          {image && <img src={image} alt="Selected" className="displayed-image" />}
        </div>
        <div className="button-container">
          <button onClick={() => document.getElementById('fileInput').click()} className="left-button">
            Take a Picture
          </button>
          <button onClick={handleRedirect} className="right-button">
            Go to URL
          </button>
        </div>
        <input
          type="file"
          id="fileInput"
          accept="image/*"
          capture="camera"
          style={{ display: 'none' }}
          onChange={handleImageChange}
        />
      </header>
    </div>
  );
}

export default App;
